<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <b-card class="mt-4" v-if="user">
                    <div class="clearfix">
                        <h1 class="float-left">{{ user.name }}</h1>
                        <div class="float-right">
                            <b-button variant="primary" class="btn btn-sm btn-icon-only rounded-circle"
                                      @click="editBasicData(user)">
                                <i class="simple-icon-pencil"></i>
                            </b-button>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>Username</h3>
                                <span>{{ user.username }}</span>
                            </b-col>
                        </div>
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>Email</h3>
                                <span>{{ user.email }}</span>
                            </b-col>
                        </div>
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>Contact Number</h3>
                                <span>{{ user.phone }}</span>
                            </b-col>
                        </div>
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>WhatsApp Number</h3>
                                <span>{{ user.whatsapp_number }}</span>
                            </b-col>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="card card-pricing border-0 text-center mb-4" v-if="shop">
                    <div class="card-header bg-transparent clearfix">

                        <div class="float-left text-left col-md-10">
                            <h1 v-if="shop.name">{{ shop.name }}
                                <img :src="shop.logo" style="max-width: 50px"
                                     alt=""></h1>
                            <span>{{ shop.description }}</span>
                        </div>
                        <div class="mt-3 float-right col-md-2 text-right">
                            <b-button variant="primary" class="btn btn-sm btn-icon-only rounded-circle"
                                      @click="editShopItem(shop)">
                                <i class="simple-icon-pencil"></i>
                            </b-button>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="mt-3 row">
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Contact Number</h3>
                                    <span>{{ shop.contact_number }}</span>
                                </b-col>
                                <b-col class="mt-3">
                                    <h3>Telegram</h3>
                                    <span>{{ shop.telegram_number }}</span>
                                </b-col>
                            </div>
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Email</h3>
                                    <span>{{ shop.email }}</span>
                                </b-col>
                                <b-col class="mt-3">
                                    <h3>Signal</h3>
                                    <span>{{ shop.signal_number }}</span>
                                </b-col>
                            </div>
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Whatsapp</h3>
                                    <span>{{ shop.whatsapp_number }}</span>
                                </b-col>
                            </div>
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Address</h3>
                                    <strong><span>{{ shop.address }}</span></strong><br>
                                    <span>Domain : {{ shop.domain }}</span><br>
                                    <span>latitude :{{ shop.latitude }} </span><br>
                                    <span>longitude : {{ shop.longitude }}</span><br>
                                </b-col>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>

        <b-modal title="Details" id="detailsModal" size="lg" no-close-on-esc hide-footer @hidden="detailsModel={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(basicUpdate)">
                    <div class="row">
                        <div class="col-md-12">
                            <validated-input name="Name" label="Name" v-model="detailsModel.name"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Email" label="Email" v-model="detailsModel.email"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Phone" label="Phone Number" v-model="detailsModel.phone" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Whatsapp" label="WhatsApp Number" v-model="detailsModel.whatsapp_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-12">
                            <b-button @click="basicUpdate" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <b-modal title="Details" id="modal" size="lg" no-close-on-esc hide-footer
                 @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-6">
                            <validated-input name="Name" label="Name" v-model="model.name"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Email" label="Email" v-model="model.email"/>
                        </div>
                        <div class="col-md-12 form-group">
                            <b-form-file alternative name="Icon" label="Icon"
                                         v-model="model.logo"
                                         />
                        </div>
                        <div class="col-md-12">
                            <validated-input name="Description" label="Description"
                                             v-model="model.description"/>
                        </div>
                        <div class="col-md-12">
                            <validated-input name="Address" label="Address"
                                             v-model="model.address"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="contact_number" label="contact_number"
                                             v-model="model.contact_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="whatsapp_number" label="whatsapp_number"
                                             v-model="model.whatsapp_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="telegram_number" label="telegram_number"
                                             v-model="model.telegram_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="signal_number" label="signal_number"
                                             v-model="model.signal_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="longitude" label="longitude"
                                             v-model="model.longitude"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="latitude" label="latitude"
                                             v-model="model.latitude"/>
                        </div>

                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '@data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'UserAndShopDetails',
    computed: {
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            user: {},
            shop: {},
            detailsModel: {
                id: '',
                name: '',
                email: '',
                phone: '',
                whatsapp_number: ''
            },
            model: {
                id: '',
                owner: '',
                enabled: false,
                name: '',
                email: '',
                logo: null,
                description: '',
                address: '',
                contact_number: '',
                whatsapp_number: '',
                telegram_number: '',
                signal_number: '',
                latitude: '',
                longitude: ''
            }

        };
    },
    mounted () {
        this.loadData();
        this.shopData();
    },
    methods: {
        async loadData () {
            const response = await axios.post(urls.userAddress.details + '?id=' + this.$route.params.id);
            this.setData(response);
        },
        async shopData () {
            const shopResponse = await axios.post(urls.shopProduct.shopdetailsadmin + '?id=' + this.currentUser.shop_id);
            this.setShopData(shopResponse);
            console.log('this.currentUser.shop_id =>', this.currentUser.shop_id);
        },
        setData (response) {
            console.log('this.res', response);
            if (response.data.User) {
                this.user = response.data.User[0];
                console.log('this.user', this.user);
            }
        },
        // basic user details //
        openBasicModal () {
            this.$bvModal.show('detailsModal');
        },

        async basicUpdate () {
            let url;
            if (this.detailsModel.id) {
                url = urls.users.update;
            } else {
                url = urls.users.create;
            }

            const response = await axios.form(url, this.detailsModel);
            this.user.push(response.data.obj);
            this.$bvModal.hide('detailsModal');
        },

        async editBasicData (item) {
            this.detailsModel = {
                id: item.id,
                name: item.name,
                email: item.email,
                phone: item.phone,
                whatsapp_number: item.whatsapp_number
            };

            this.openBasicModal();
        },

        // Shop Details //

        openShopModal () {
            this.$bvModal.show('modal');
        },

        setShopData (response) {
            console.log('response, response', response);
            if (response.data.shop) {
                this.shop = response.data.shop[0];
                console.log('this.shop', this.shop);
            }
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.shop.update;
            }
            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setShopData(response);
                this.$bvModal.hide('modal');
            }
        },

        async editShopItem (rowData) {
            this.model = {
                id: rowData.id,
                owner: rowData.owner,
                name: rowData.name,
                email: rowData.email,
                description: rowData.description,
                contact_number: rowData.contact_number,
                address: rowData.address,
                whatsapp_number: rowData.whatsapp_number,
                telegram_number: rowData.telegram_number,
                signal_number: rowData.signal_number,
                domain: rowData.domain,
                latitude: rowData.latitude,
                longitude: rowData.longitude
            };
            this.openShopModal();
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }

    }
};
</script>

<style>

</style>
